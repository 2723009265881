import { CheckCircle2Icon } from 'lucide-react';
import ConnectDialogue from './ConnectDialogue';
import DisconnectDialogue from './DisconnectDialogue';
import useIntegrations from './useIntegrations';

export enum IntegrationType {
  JIRA = 'JIRA',
  SLACK = 'SLACK',
}
export interface IntegrationItemProps {
  icon: string;
  name: string;
  provider: string;
  description: string;
  enabled: boolean;
  type: IntegrationType;
}

interface IntegrationItemComponentProps extends IntegrationItemProps {}

function IntegrationItem({
  icon,
  name,
  provider,
  description,
  enabled,
  type,
}: IntegrationItemComponentProps) {
  const {
    connection,
    setConnection,
    isLoading,
    unSetConnection,
    disconnecting,
    connecting,
  } = useIntegrations({
    type,
  });
  return (
    <div className="flex flex-col flex-wrap w-full h-[315px] overflow-hidden border shadow-md p-6 rounded-md bg-[--card-bg] transition-all">
      <div className="flex justify-between items-center">
        <div className="flex gap-4">
          <img className="w-10 h-10 m-auto" src={icon} />
          <div className="justify-start flex flex-col">
            <p className="text-xl font-semibold">{name}</p>
            <p className="text-muted-foreground text-xs flex">
              By
              {provider}
            </p>
          </div>
        </div>
        {isLoading ? (
          <div className=" w-8 h-8 border-4 rounded-full animate-pulse" />
        ) : null}
        {connection?.url && enabled && !isLoading ? (
          <CheckCircle2Icon className="text-lime-500 w-8 h-8" />
        ) : null}
      </div>
      <div>
        <p className="text-muted-foreground text-base mt-4 mb-3 h-[150px]">
          {description}
        </p>
      </div>
      <div className="flex -mb-1">
        {connection?.url ? (
          <DisconnectDialogue
            unSetConnection={unSetConnection}
            disconnecting={disconnecting ?? false}
          />
        ) : (
          <ConnectDialogue
            isLoading={isLoading}
            connecting={connecting ?? false}
            setConnection={setConnection}
            enabled={enabled}
            type={type}
          />
        )}
      </div>
    </div>
  );
}

export default IntegrationItem;
